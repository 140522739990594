<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/BasicConfig/FreightTemplate"
            >运费模版 /
          </router-link>
          <span class="crumbs_item crumbs_last">新建运费版</span>
        </span>
      </div>
    </div>

    <!-- 详细内容 -->
    <div class="formData">
      <a-row class="rows">
        <a-col class="left required" span="3">模版名称</a-col>
        <a-col class="right" span="6">
          <a-input v-model="form.name" placeholder="请输入模版名称" />
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="3">发货地址</a-col>
        <a-col class="right" span="6">
          <Province style="width:100%" @regionCity='FunProvince' :value="shippingAddressValue"></Province>
        </a-col>
      </a-row>
      <a-row class="rows">
        <a-col class="left required" span="3">是否包邮</a-col>
        <a-col class="right" span="20">
          <a-radio-group class="radioGroup" v-model="form.noFreight">
            <a-radio :value="1">自定义运费</a-radio>
            <a-radio :value="2">全部包邮</a-radio>
          </a-radio-group>
        </a-col>
      </a-row>
      <!-- 全国包邮 -->
      <template v-if="form.noFreight == 1">
        <a-row class="rows">
          <a-col class="left required" span="3">计价方式</a-col>
          <a-col class="right" span="20">
            <a-radio-group class="radioGroup" v-model="form.priceManner">
              <a-radio :value="1">按件数</a-radio>
              <a-radio :value="2">按重量</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col class="left" span="3">运送配置</a-col>
          <a-col class="right" span="20">
            <div class="transport">
              <div class="titleH4">
                <span>默认运费</span>
                <a-input-number id="inputNumber" class="input" v-model="form.defaultCount" :min="1" :max="10000" />
                <span>{{ form.priceManner == 1 ? "件" : "kg" }}内</span>
                <a-input-number id="inputNumber" class="input" v-model="form.defaultPrice" :min="1" :max="10000" />
                <span>元，每增加</span>
                <a-input-number id="inputNumber" class="input" v-model="form.addCount" :min="1" :max="10000" />
                <span>{{ form.priceManner == 1 ? "件" : "kg" }}，增加运费</span>
                <a-input-number id="inputNumber" class="input" v-model="form.addPrice" :min="1" :max="10000" />

                <span>元。</span>
              </div>
              <table class="table" v-if="form.freightShipList.length != 0">
                <tr>
                  <th>运送到</th>
                  <th style="width: 120px">
                    首{{ form.priceManner == 1 ? "件数" : "重" }}（{{
                      form.priceManner == 1 ? "件" : "kg"
                    }}）
                  </th>
                  <th style="width: 120px">首费（元）</th>
                  <th style="width: 120px">
                    续{{ form.priceManner == 1 ? "件数" : "重" }}（{{
                      form.priceManner == 1 ? "件" : "kg"
                    }}）
                  </th>
                  <th style="width: 120px">续费（元）</th>
                  <th style="width: 120px">操作</th>
                </tr>
                <tbody>
                  <tr v-for="(item,index) in form.freightShipList" :key="index">
                    <td>
                      <span><a @click="onOpenView(item.provice)">查看所选地区</a></span>
                    </td>
                    <td><a-input v-model="item.firstCount" /></td>
                    <td><a-input v-model="item.firstPrice" /></td>
                    <td><a-input v-model="item.addCount" /></td>
                    <td><a-input v-model="item.addPrice" /></td>
                    <td>
                      <a @click="addAddress(1,item,index)">添加地址</a>
                      <span>｜</span>
                      <a @click="removeTransportList(index)">删除</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="addBtn">
                <a @click="addTransportList()">为指定的地区城市设置运费</a>
                <span>（除指定地区外，其余地区的运费均采用“默认运费”）</span>
              </p>
            </div>
          </a-col>
        </a-row>
        <a-row class="rows">
          <a-col class="left" span="3">制定包邮条件</a-col>
          <a-col class="right" span="20">
            <a-radio-group class="radioGroup" v-model="form.noAssign">
              <a-radio :value="0">关闭</a-radio>
              <a-radio :value="1">开启</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="rows" v-if="form.noAssign == 1">
          <a-col class="left" span="3">运送配置</a-col>
          <a-col class="right" span="20">
            <div class="configuration">
              <table class="table">
                <tr>
                  <th>选择地区</th>
                  <th style="width: 480px">设置包邮条件</th>
                  <th style="width: 120px">操作</th>
                </tr>
                <tbody>
                  <tr v-for="(item, index) in form.freightPostageList" :key="index">
                    <td>
                      <span><a @click="onOpenView(item.provice)">查看所选地区</a></span>
                    </td>
                    <td>
                      <a-select style="width: 120px" @change="onPostChange(item)" v-model="item.type" placeholder="请选择">
                        <a-icon slot="suffixIcon" type="caret-down" />
                        <a-select-option :value="1">件数</a-select-option>
                        <a-select-option :value="2">金额</a-select-option>
                        <a-select-option :value="3">件数+金额</a-select-option>
                      </a-select>
                      <template v-if="item.type == '1'">
                        <span style="margin: 0 8px">满</span>
                        <a-input v-model="item.piece" style="width: 100px" />
                        <span style="margin-left: 8px">件包邮</span>
                      </template>
                      <template v-if="item.type == '2'">
                        <span style="margin: 0 8px">满</span>
                        <a-input v-model="item.element" style="width: 100px" />
                        <span style="margin-left: 8px">元包邮</span>
                      </template>
                      <template v-if="item.type == '3'">
                        <span style="margin: 0 8px">满</span>
                        <a-input v-model="item.piece" style="width: 100px" />
                        <span style="margin-left: 8px">件，</span>
                        <a-input v-model="item.element" style="width: 100px" />
                        <span style="margin-left: 8px">元以上包邮</span>
                      </template>
                    </td>
                    <td>
                      <a @click="addAddress(2,item,index)">添加地址</a>
                      <span>｜</span>
                      <a @click="removeFreeShippingList(index)">删除</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="addBtn">
                <a @click="addFreeShippingList()">添加指定包邮城市</a>
              </p>
            </div>
          </a-col>
        </a-row>
        <a-row class="rows" v-if="false">
          <a-col class="left" span="3">不配送区域</a-col>
          <a-col class="right" span="20">
            <a-radio-group class="radioGroup" v-model="form.noArea">
              <a-radio :value="0">关闭</a-radio>
              <a-radio :value="1">开启</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="rows" style="margin-top: 8px" v-if="form.noArea == 1">
          <a-col class="right" span="20" offset="3">
            <div>
              <a-button size="small" @click="addAddress(3,form.freightDeliveryList[0],0)"
                >添加区域</a-button
              >
            </div>
            <div class="freightDeliveryList">
              <div
                class="item"
                v-for="(item, index) in freightDeliveryArry"
                :key="index"
              >
                <span>{{ item.name }}</span>
                <a-icon class="icon" @click="onDelDelivery(item.code,index)" type="close-circle" theme="filled" />
              </div>
              <span v-if="freightDeliveryArry.length == 0" style="color: #999"
                >还没有添加区域～</span
              >
            </div>
          </a-col>
        </a-row>
      </template>
      <!-- 全国包邮 Esc -->
      <a-row class="rows">
        <a-col span="21" offset="3">
          <a-button type="primary" style="margin-right: 30px" :loading="PreventLoad" @click="onSaveForm()"
            >确认</a-button
          >
          <a-button @click="$router.go(-1)">取消</a-button>
        </a-col>
      </a-row>
    </div>
    <!-- 弹框内容 -->
    <a-modal v-model="modalAreaShow" title="选择区域" @ok="handleOk" @cancel="onCancel" :maskClosable='false'>
      <div class="adress_list">
        <div class="adress_item" v-for="(item, index) in treeData" :key="index">
          <div class="largeArea_L">
            <span>{{item.name}}</span>
          </div>
          <div class="provice_R">
            <div class="provice_item" v-for="(items, indexs) in item.children" :key="indexs + 'sub'">
              <p>
                <a-checkbox 
                 v-model="items.isCheck"
                 :checked="items.checkAll" 
                 :indeterminate="items.indeterminate"
                 :disabled="items.indeterminate"
                 :value="items.code" 
                 @change="onParentChange(items)">{{ items.name }}</a-checkbox>
                <a-icon slot="suffixIcon" @click="items.isOpen = !items.isOpen" :type="items.isOpen ? 'caret-up':'caret-down'" />
              </p>
              <div class="provice_child" v-show="items.isOpen">
                <span v-for="(childItem, i) in items.children" :key="i + 'child'" class="provice_child_item">
                  <a-checkbox
                  v-model="childItem.isCheck"
                  :value="childItem.code"
                  :checked="proviceCodeArry.length ? proviceCodeArry.indexOf(parseInt(childItem.code)) != -1 : false"
                  @change="onSubChange(items,childItem)"
                  >{{ childItem.name }}</a-checkbox>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 指定地区弹窗 -->
    <a-modal v-model="modalViewShow" title="指定地区" @ok="modalViewShow = false" width="500px">
      <div class="adress_list_area">
        <div class="adress_item" v-for="item in selectAearArry" :key="item.code">
          <p>{{item.name}}：</p>
          <span v-for="subItem in item.children" :key="subItem.code">{{subItem.name}}</span>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import Province from '@/components/provinceCity'

export default {
  // 可用组件的哈希表
  components: { HeaderBox, Province },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 发货省市区
      shippingAddressValue:[],

      loading:false,
      form:{   // 表单对象
        freightId:'',
        name: '',  // 模版名称
        provice: "", // 发货地址-省
        city: "", // 发货地址-市
        area: "", // 发货地址-区
        noFreight: 1,  // 是否包邮 1.自定义运费 2.包邮
        priceManner:1,  	// 计价方式1.按件2.按重量

        defaultCount:'',   	// 默认--件/重量
        defaultPrice:'',  // 默认--元
        addCount:'',  // 续--件/重量
        addPrice:'',  // 续--元

        freightShipList:[],  // 运费模版运送配置

        freightPostageList:[],  // 包邮条件配置

        freightDeliveryList:[  //运费模版不配送配置
          {
            provice:[]
          }
        ],

        noAssign:0, 	//指定包邮条件0关闭1开启
        noArea:0,   //不配送区域0关闭1开启
      },

      proviceCodeArry: [],  // 暂存code
      freightShipArry: [],  // 暂存对象

      modalViewShow:false,
      selectAearArry:[],

      //弹窗
      modalAreaShow: false,
      proviceIndex:0,
      proviceType:0,

      treeData: [],
      otherTreeDta:[],

      freightDeliveryArry:[],



      // 1按件计费，2按重量计费
      priceManner: "1",
      // 指定计价配置
      transportList: [],
      


      noAssign: 0, // 定制包邮1关闭，2开启
      noArea: 0, // 不配送区域1关闭，2开启
      freightDeliveryList: [], // 不配送区域


      // 传输数据
      noFreight: 1, // 1自定义运费，2包邮
      name: "", //模板名称
      //省市区
      provice: null,
      city: null,
      area: null,
      PreventLoad: false
    };
  },
  // 事件处理器
  methods: {
    // 获取发货省市区编码
    FunProvince(data){
      this.form.provice = data[0]
      this.form.city = data[1]
      this.form.area = data.length > 2 ? data[2] : ''

      this.shippingAddressValue = data
    },

    // 保存模板
    onSaveForm(){
      if(this.FunCheck()) return  // 非空字段校验
      this.PreventLoad = true;
      this.form.freightShipList.forEach(element => {
        element.provice = JSON.stringify(element.provice)
      })
      this.form.freightPostageList.forEach(element => {
        element.provice = JSON.stringify(element.provice)
      })
      this.form.freightDeliveryList.forEach(element => {
        element.provice = JSON.stringify(element.provice)
      })
      // 全部包邮--重组一个新对象
      let freeShipObj = {
        freightId: this.form.freightId,
        name: this.form.name,  // 模版名称
        provice: this.form.provice, // 发货地址-省
        city: this.form.city, // 发货地址-市
        area: this.form.area, // 发货地址-区
        noFreight: this.form.noFreight,  // 是否包邮 1.自定义运费 2.包邮
        noArea:0,
        noAssign:0,
      }
      this.$ajax({
        url: this.form.freightId ? '/hxclass-management/product-freight/manage/update' : '/hxclass-management/product-freight/manage/save',
        method: this.form.freightId ? 'put' : 'post',
        params: this.form.noFreight == 1 ? this.form : freeShipObj,  // 如果是包邮用定义包邮的对象
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.warning(res.message);
        }
        this.PreventLoad = false;
      });
    },

    // 非空字段校验
    FunCheck(){
      let isCheck = false
      if(!this.form.name){ // 校验模板名称
        isCheck = true
        this.$message.warning("请输入模板名称");
        return isCheck
      }
      if(!this.shippingAddressValue.length){ // 校验发货地址
        isCheck = true
        this.$message.warning("请选择发货地址");
        return isCheck
      }
      // 校验运送配置
      if(this.form.noFreight==1 && (!this.form.defaultCount || !this.form.defaultPrice || !this.form.addCount || !this.form.addPrice)){ // 校验运费配置
        isCheck = true
        this.$message.warning("请完善运送配置");
        return isCheck
      }
      if(this.form.freightShipList.length){ // 校验指定地区运费模板


        let arry = this.form.freightShipList
        for(let i = 0; i<arry.length; i++) {
          if(!arry[i].firstCount || !arry[i].firstPrice || !arry[i].addCount || !arry[i].addPrice){
            isCheck = true
            this.$message.warning("请检查【指定地区设置】第" + (i + 1 ) +"条信息是否填写完整");
            break;
          }
          if(!arry[i].provice.length){
            isCheck = true
            this.$message.warning("【指定地区设置】第" + (i + 1 ) +"条地区未添加城市");
            break;
          }
        }

        if(this.form.noAssign && !this.form.freightPostageList.length){ // 校验包邮条件
          isCheck = true
          this.$message.warning("请添加指定包邮城市");
        }

        if(this.form.noAssign == 1 && this.form.freightPostageList.length){ // 校验包邮条件
          let arry = this.form.freightPostageList
          for(let i = 0; i<arry.length; i++) {
            if(!arry[i].provice.length){
              isCheck = true
              this.$message.warning("【制定包邮条件】第" + (i + 1 ) + '条地区未添加城市');
              break;
            }
            if(!arry[i].type){
              isCheck = true
              this.$message.warning("【制定包邮条件】第" + (i + 1 ) + '条未设置包邮条件');
              break;
            }
            if((arry[i].type == 1 && !arry[i].piece) || (arry[i].type == 2 && !arry[i].element) || (arry[i].type == 3 && (!arry[i].piece || !arry[i].element))){
              isCheck = true
              this.$message.warning("请完善【制定包邮条件】第" + (i + 1 ) + '条数据');
              break;
            }
          }
        }

        if(this.form.noArea && !this.form.freightDeliveryList[0].provice.length){ // 校验包邮条件
          isCheck = true
          this.$message.warning("请添加不配送的地区");
        }

        return isCheck
      }
      if(this.form.noAssign && !this.form.freightPostageList.length){ // 校验包邮条件
        isCheck = true
        this.$message.warning("请添加指定包邮城市");
        return isCheck
      }
      if(this.form.noAssign == 1 && this.form.freightPostageList.length){ // 校验包邮条件
        let arry = this.form.freightPostageList
        for(let i = 0; i<arry.length; i++) {
          if(!arry[i].provice.length){
            isCheck = true
            this.$message.warning("【制定包邮条件】第" + (i + 1 ) + '条地区未添加城市');
            break;
          }
          if(!arry[i].type){
            isCheck = true
            this.$message.warning("【制定包邮条件】第" + (i + 1 ) + '条未设置包邮条件');
            break;
          }
          if((arry[i].type == 1 && !arry[i].piece) || (arry[i].type == 2 && !arry[i].element) || (arry[i].type == 3 && (!arry[i].piece || !arry[i].element))){
            isCheck = true
            this.$message.warning("请完善【制定包邮条件】第" + (i + 1 ) + '条数据');
            break;
          }
        }
        return isCheck
      }
      if(this.form.noArea && !this.form.freightDeliveryList[0].provice.length){ // 校验包邮条件
        isCheck = true
        this.$message.warning("请添加不配送的地区");
        return isCheck
      }
    },

    // 获取树形省市区数据
    getdataList() {
      this.$ajax({
        url: "/hxclass-management/province-manage/manage/freight",
        method: "get",
      }).then((res) => {
        res.data.forEach(element => {
          element.children.forEach(subItem => {
            subItem.isOpen = false // 是否打开子项
            subItem.isCheck = false  // 是否选中
            subItem.isCheckAll = false  // 是否全选
            subItem.indeterminate = false  // 是否半选
            subItem.children.forEach(childItem => {
              childItem.isCheck = false  // 是否选中
            });
          });
        })
        this.otherTreeDta = JSON.stringify(res.data);
        this.treeData = res.data;
      });
    },

    // 获取详情
    getFormDetai(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/product-freight/manage/selectbyid",
        params: {
          freightId: this.form.freightId,
        },
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.form = res.data
          if(this.form.freightShipList){
            this.form.freightShipList.forEach(element => {
              element.provice = JSON.parse(element.provice)
            })
          } else {
            this.form.freightShipList = []
          }



          if(this.form.freightPostageList){
            this.form.freightPostageList.forEach(element => {
              element.provice = JSON.parse(element.provice)
            })
          } else {
            this.form.freightPostageList = []
          }



          if(this.form.freightDeliveryList){
            this.form.freightDeliveryList.forEach(element => {
              element.provice = JSON.parse(element.provice)
            })
          } else {
            this.form.freightDeliveryList = [
              {
                provice:[]
              }
            ]
          }
          let noAearArry = JSON.stringify(this.form.freightDeliveryList[0].provice)
          this.freightDeliveryArry = JSON.parse(noAearArry)
          this.shippingAddressValue = [this.form.provice, this.form.city]  // 省市区回显
          if(this.form.area){  // 判断部分地区没有区
            this.shippingAddressValue.push(this.form.area)
          }
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },

    // 查看所选地区
    onOpenView(e){
      let proviceArry = []
      let treeData = []
      this.selectAearArry = typeof(e) == 'object' ? e : JSON.parse(e)   // 判断是json对象还是数组
      if(!this.selectAearArry.length){
        this.$message.warning("还未选择地区");
        return
      }
      this.selectAearArry.forEach(element => {
        if((element.code).slice(2,6) == '0000'){
          proviceArry.push({
            name: element.name,
            code: element.code,
            children: [],
          })
        }
      });
      proviceArry.forEach((element,index) => {
        this.selectAearArry.forEach(subItem => {
          if(((element.code).slice(0,2) == (subItem.code).slice(0,2)) && (element.code != subItem.code)){
            element.children.push({
              name: subItem.name,
              code: subItem.code,
              children: [],
            })
          }
        });
      });
      this.selectAearArry = proviceArry
      this.modalViewShow = true
    },


    // 父级全选/反选
    onParentChange(e){
      let parentCode = parseInt(e.code)
      let i = this.proviceCodeArry.indexOf(parentCode)
      if(i < 0){
        // 省code暂存起来
        this.proviceCodeArry.push(parentCode)
        this.freightShipArry.push({
          name: e.name,
          code: e.code,
        })
      } else {
        this.proviceCodeArry.splice(i,1)
        this.freightShipArry.splice(i,1)
      }


      let conut = 0
      e.children.forEach(element => {
        element.isCheck = true
        let childCode = parseInt(element.code)
        let j = this.proviceCodeArry.indexOf(childCode)
        if(j < 0){
          // 市code暂存起来
          this.proviceCodeArry.push(childCode)
          this.freightShipArry.push({
            name: element.name,
            code: element.code,
          })
        } else {
          this.proviceCodeArry.splice(j,1)
          this.freightShipArry.splice(j,1)
        }


        if(element.isCheck){ // 用来计算父级底下有多少子集被选
          conut = conut + 1
        }

      });
      
      if(!e.isCheck){  // 父级取消全选时，将所有的子集状态改为false
        e.children.forEach(item => {
          item.isCheck = false
        })
        conut = 0
      }

      if(e.children.length == conut) { 
        e.checkAll = true  // 如果 子集所选条数与父级的数组相等，那说明父级全选
      } else if(conut > 0) {
        e.isCheck = true
        e.checkAll = false
      } else if (conut == 0) {
        e.checkAll = false
      }
    },

    // 子集选择/取消
    onSubChange(ParentItem,ChildItem){
      let conut = 0
      ParentItem.children.forEach(item => {
        if(item.isCheck){
          conut = conut + 1
        }
      })
      // 用来标识父级的状态
      if(ParentItem.children.length == conut){
        ParentItem.isCheck = true
        ParentItem.checkAll = true
        ParentItem.indeterminate = false
      } else if(conut > 0) {
        ParentItem.isCheck = true
        ParentItem.checkAll = false
        ParentItem.indeterminate = true
      } else if (conut == 0) {
        ParentItem.indeterminate = false
        ParentItem.checkAll = false
        ParentItem.isCheck = false
      }


      // 市code暂存起来
      let childCode = parseInt(ChildItem.code)
      let i = this.proviceCodeArry.indexOf(childCode)
      if(i < 0){
        this.proviceCodeArry.push(childCode)
        this.freightShipArry.push({
          name: ChildItem.name,
          code: ChildItem.code,
        })
      } else {
        this.proviceCodeArry.splice(i,1)
        this.freightShipArry.splice(i,1)
      }


      // 省code暂存起来
      let parentCode = parseInt(ParentItem.code)
      let j = this.proviceCodeArry.indexOf(parentCode)
      if((ParentItem.isCheck || ParentItem.checkAll) && j < 0){
        this.proviceCodeArry.push(parentCode)
        this.freightShipArry.push({
          name: ParentItem.name,
          code: ParentItem.code,
        })
      } else if (!ParentItem.isCheck && !ParentItem.checkAll && j != -1) {
        this.proviceCodeArry.splice(j,1)
        this.freightShipArry.splice(j,1)
      }
    },

    // 添加地址
    addAddress(type,e,index){
      let proviceCodes = e.provice // 获取已选的地址
      let otherArry = JSON.stringify(proviceCodes)
      this.freightShipArry = JSON.parse(otherArry)

      let newTreeData = JSON.parse(this.otherTreeDta)
      if(proviceCodes.length){
        proviceCodes.forEach(item => { // 遍历 -- 已选的省市
          this.proviceCodeArry.push(parseInt(item.code))
          newTreeData.forEach(areaItem => {  // 遍历 -- 所有大区
            areaItem.children.forEach(proviceItem => {  // 遍历 - 省
              if(proviceItem.code == item.code){
                proviceItem.isCheck = true
                let conut = 0
                proviceItem.children.forEach(cityItem => {  // 遍历所选省下的-市
                  proviceCodes.forEach(childItem => {
                    if(childItem.code == cityItem.code){
                      conut = conut + 1  // 计算是否该省底下的市是否全选
                    }
                  });
                });

                
                if(conut == proviceItem.children.length){
                  proviceItem.isCheckAll = true
                  proviceItem.indeterminate = false
                } else if(conut > 0) {
                  proviceItem.isCheckAll = false
                  proviceItem.indeterminate = true
                }
              }
              proviceItem.children.forEach(cityItem => {  // 遍历-市
                if(cityItem.code == item.code){
                  cityItem.isCheck = true
                }
              });
            });

          })
        })
        this.treeData = newTreeData
      } else {
        this.treeData = newTreeData
      }
      this.proviceIndex = index
      this.proviceType = type
      this.modalAreaShow = true
    },
    // 添加指定运送配置
    addTransportList() {
      this.form.freightShipList.push({
        provice:[],   // 省
        firstCount:'',  // 首/件/重量 
        firstPrice:'',   // 首/元
        addCount:'',   // 	续/件/重量
        addPrice:'',   //	续/元
      });
    },
    // 删除指定运送配置
    removeTransportList(i) {
      this.form.freightShipList.splice(i, 1)
    },



    // 添加包邮地区
    addFreeShippingList() {
      this.form.freightPostageList.push({
        provice:[],  // 省
        element:'',  // 元
        piece:'',  // 件
        type:undefined
      });
    },
    // 删除包邮地区
    removeFreeShippingList(i) {
      this.form.freightPostageList.splice(i, 1)
    },

    // 删除不配送的地区
    onDelDelivery(code,index){
      let arry = this.form.freightDeliveryList[0].provice
      let arryAllCode = []
      let selectCode = []
      arry.forEach(element => {
        arryAllCode.push(element.code)
      });


      if(code.slice(2,6) == '0000'){
        arry.forEach(element => {
          if((element.code).slice(0,2) == code.slice(0,2)){
            selectCode.push(element.code)
          }
        });

        selectCode.forEach(element => {
          let i = arryAllCode.indexOf(element)
          arryAllCode.splice(i,1)
          this.form.freightDeliveryList[0].provice.splice(i,1)
        });
      } else {
        this.form.freightDeliveryList[0].provice.splice(index, 1)
      }

      let noAreaArry = JSON.stringify(this.form.freightDeliveryList[0].provice)
      this.freightDeliveryArry = JSON.parse(noAreaArry)
    },

    // 包邮方式切换
    onPostChange(e){
      e.piece = ''
      e.element = ''
    },

    handleOk(e) {
      this.modalAreaShow = false;
      let arry = JSON.stringify(this.freightShipArry)
      if(this.proviceType == 1){
        this.form.freightShipList[this.proviceIndex].provice = JSON.parse(arry)
      } else if (this.proviceType == 2) {
        this.form.freightPostageList[this.proviceIndex].provice = JSON.parse(arry)
      } else if (this.proviceType == 3) {
        this.form.freightDeliveryList[0].provice = JSON.parse(arry)
        this.freightDeliveryArry = JSON.parse(arry)
      }

      this.freightShipArry = []
      this.proviceCodeArry = []
      this.proviceIndex = 0
      this.proviceType = 0
    },
    onCancel(){
      this.freightShipArry = []
      this.proviceCodeArry = []
      this.proviceIndex = 0
      this.proviceType = 0
    },
    //下拉框选择
    onChange(value) {
      this.provice = value[0];
      this.city = value[1];
      this.area = value[2];
    },
    // 获取数据
    putData() {
      this.$ajax({
        url: "/hxclass-management/product-freight/manage/save",
        method: "post",
        params: {
          name: this.name,
          noFreight: this.noFreight,
          provice: this.provice,
          city: this.city,
          area: this.city,
          noAssign: this.noAssign,
          noArea: this.noArea,
          priceManner: this.priceManner,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.$router.go(-1);
        } else {
          this.$message.success(res.code);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.form.freightId = this.$route.query.freightId
    if(this.form.freightId){
      this.getFormDetai() // 获取form详情
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
  },
};
</script>

<style lang="less" scoped>
a {
  color: #333333;
}
.rows {
  margin-top: 20px;
  .left {
    text-align: right;
    height: 32px;
    line-height: 32px;
    &.required::before {
      content: "*";
      color: red;
      margin-right: 2px;
    }
    &::after {
      content: "：";
    }
  }
}
.radioGroup {
  height: 32px;
  line-height: 32px;
}
.transport {
  .titleH4 {
    background-color: #f6f6fc;
    padding: 12px 16px;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    > span {
      font-size: 14px;
      font-weight: 500;
      color: #333;
    }
    .input {
      margin: 0 12px;
      width: 100px;
    }
  }
  .table {
    width: 100%;
    border-radius: 5px;
    border-collapse: collapse;
    margin-top: 8px;
    th,
    td {
      border: 1px solid #eee;
      height: 40px;
      color: #333;
      font-size: 12px;
      font-weight: 400;
      padding: 5px 15px;
    }
    th {
      background-color: #f6f6fc;
    }
    td {
      a {
        color: @theme;
      }
    }
  }
  .addBtn {
    font-size: 12px;
    font-weight: 400;
    color: #999;
    margin-top: 16px;
    a {
      color: @theme;
    }
  }
}
.configuration {
  .table {
    .transport .table;
  }
  .addBtn {
    .transport .addBtn;
  }
}
.freightDeliveryList:extend(.transport .titleH4) {
  margin-top: 17px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  padding-right: 0;
  padding-bottom: 0;
  flex-wrap: wrap;
  .item {
    height: 30px;
    box-sizing: border-box;
    min-width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    padding: 0 16px;
    margin-right: 24px;
    margin-bottom: 12px;
    .icon {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      &:hover {
        color: @theme;
        cursor: pointer;
      }
    }
  }
}

.checkboxs {
  display: flex;
  .checkboxm {
    width: 150px;
  }
}
.check:hover {
  .checknone {
    display: block !important;
  }
}
.checknone {
  position: relative;
  z-index: 112;
  display: none;
}
/deep/.ant-modal-content {
  width: 800px;
}


// 选择区域
.adress_list{
  .adress_item{
    display: flex;
    .largeArea_L{
      width: 80px;
    }
    .provice_R{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .provice_item{
        display: inline-block;
        width: 180px;
        margin-bottom: 10px;
        .provice_child{
          width: 210px;
          margin-top: 5px;
          .provice_child_item{
            display:inline-block;
            min-width: 90px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.adress_list_area{
  .adress_item{
    margin-bottom: 15px;
    >p{
      color: #333;
      font-size: 16px;
      // margin-bottom: 5px;
    }
    >span{
      display: inline-block;
      font-size: 14px;
      min-width: 150px;
    }
  }
}
</style>
